*
{
    margin: 0;
    padding: 0;
    overflow-x: hidden;

}

html
{
    background: #f1eff4;
    position: flex;
    max-width: 100vw;
    height: 100vh;
}

.webgl
{
    z-index: -1;
    position: fixed;
    padding: 0;
    top: 0;
    left: 0;
    outline: none;
    width: auto;
    height: auto;
    overflow: hidden;
}

.header
{
    /* header */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8vmin;
    /* gap: 20vh; */

    width: 100vw;
    /* height: 10vh; */


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.logocomplete {
    display: flex;
    flex-direction: column;
}

.logonew img {
    max-width: 400px;
}

#logoinner
{
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 2.5vw;

    width: auto;
    height: auto;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

@media only screen and (max-width: 600px) {
    .logocomplete {
        display: flex;
        flex-direction: column;
        width: auto;
        height: auto;
        flex-wrap: wrap;
        padding-left: 16vmin;
    }
}


.logoblob
{
    /* logotext */
    display: flex;
    flex-direction: column;
    /* align-items: left; */

    width: auto;
    height: auto;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.logoplace
{
    /* Angell-Logo Position */

    width: auto;
    height: auto;
    
    margin-top: 60px;
    margin-bottom: 50px;
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}

.logotexta
{
    position: flex;
    width: auto;
    height: auto;
    
    font-family: 'DM Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 4.5vh;
    /* line-height: 83px; */
    
    color: #040304;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.logotextb
{
    /* Tagline */
    display: block;
    width: auto;
    height: auto;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 2.35vh;
    /* line-height: auto; */
    /* identical to box height */
    word-wrap: normal;

    text-align: center;

    color: #040304;

    flex: none;
    order: 1;
    flex-grow: 0;
}

/*@media only screen and (max-width: 600px) {*/
    .logotextb {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .logotextb span.letter {
        font-weight: bold;
        font-family: 'DM Mono';
    }
/*}*/

.blob
{
    display: flex;
    flex-direction: column;
    justify-content: center;

    /* padding: 23px; */
    gap: 4vh;
    padding: 5vh;

    width: 60vw;
    height: auto;
}


.h2
{
    width: auto;
    height: auto;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 4vh;
    
    color: #040304;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}

.p
{
    width: auto;
    height: auto;
    
    font-family: 'DM Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 2.45vh;
    
    color: #040304;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    flex-grow: 0;
    
}


.section
{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: left;
    color: #040304;
    padding-left: 6vw;
    padding-top: 8vh;
    padding-bottom: 6vh;

    width: auto;
    /* height: 100vh; */
    

}

.sectionb
{

    display: flex;
    flex-direction: row-reverse;
    /* justify-content: right; */
    align-items: flex-start;
    color: #040304;
    padding-top: 8vw;
    padding-bottom: 200px;

    width: auto;
    /* height: 100vh; */

    /* font-size: 3vmin; */

}

/* section:nth-child(odd)
{
    justify-content: flex-end;
} */


.footertexta
{
    /* footertexta */


    width: auto;
    height: auto;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 5vw;
    line-height: auto;
    /* identical to box height */




    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.footertextb
{
    /* footertextb */


    width: auto;
    height: auto;

    font-family: 'DM Mono';
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    flex-wrap: wrap;
    /* identical to box height */




    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.footerbuttontext
{
    /* Get in touch! */


    width: auto;
    height: auto;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: auto;
    /* identical to box height */


    color: #FCFCFC;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.footerbutton
{
    /* footerCTA */
    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 46px;
    gap: 10px;
    text-decoration: none;

    width: auto;
    height: auto;

    background: #623CEA;
    border-radius: 10px;
    isolation: isolate;
    z-index: 10;
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, 
                transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
}

.footerbutton:hover {
    background: white;
}

.footerbuttontext:hover {
    color: #040304 !important;
}

.social {
    margin: 30px 0;
}

.buttonbar {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.buttonbar i:hover {
    color: #623CEA;
}

.buttonbar i {
    transition: color 0.5s;
    color: white;
    font-size: 32px;
}

.social h2 {
    margin: 10px 0;
    font-size: 48px;
    font-family: 'Roboto', sans-serif;
}

.social h3 {
    margin: 10px 0;
    font-family: 'DM Mono', sans-serif;
}

.footerblob
{
    /* footerblob */

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
    padding: 0px;
    gap: 24px;

    width: auto;
    height: auto;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.footerbundle
{
    /* footer bundle */


    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    flex-wrap: wrap;
    /* position: absolute; */
    gap: 4vw;

    flex: none;

}

.footlogo
{
    width: auto;
    height: auto;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

@media only screen and (max-width: 600px) {
    .footerbundle {
        display: flex;
        flex-direction: column;
        width: auto;
        flex-wrap: wrap;
    }

    .footerblob {
        align-items: center;
        padding: 2rem;
    }
}

.footer-video {
    max-height: 480px;
}

.footer
{
    /* Footer */

    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    margin-top: 5rem;
    padding-bottom: 8rem;
    width: 100vw;
    height: auto;
    background-color: black;
    color: white;
    
    /* Inside auto layout */
    
    flex: none;
    order: 7;
    flex-grow: 0;
}

.IframeContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}